import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Home.css";

function Home() {
  return (
    <Container className="d-flex Home" fluid>
      <Row className="d-flex w-100 justify-content-center align-self-center">
        <Col>
          <h1 className="display-1 text-center">The Brownstone Co.</h1>
          <h5 className="text-center">Realty Concierge</h5>
        </Col>
      </Row>
    </Container>
  );
}
export default Home;
