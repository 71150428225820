import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../Comonponents/Footer/Footer";
import HouseListing from "../../Comonponents/HouseListing/HouseListing";

import "./Listing.css";

function Listing() {
  return (
    <>
      <Container fluid className="Listing">
        <HouseListing />;
      </Container>
      <Footer />
    </>
  );
}

export default Listing;
