import React from "react";
import { Col, Row } from "react-bootstrap";
import "./LineText.css";

function LineText(props: any) {
  const orderLeft = props.rightText ? "order-2" : "order-1";
  const orderRight = props.rightText ? "order-1" : "order-2";

  return (
    <Row className="align-items-center">
      <Col xs={2} className={orderLeft}>
        <h1>{props.text}</h1>
      </Col>
      <Col xs={10} className={orderRight}>
        <hr className="border border-3" />
      </Col>
    </Row>
  );
}
export default LineText;
