import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../Comonponents/Footer/Footer";
import LineText from "../../Comonponents/LineText/LineText";
import ShortHr from "../../Comonponents/ShortHr/ShortHr";
import "./Services.css";

function Services() {
  return (
    <>
      <Container fluid className="Services">
        <Row className="full-screen egg-shell-bg">
          <Col className="light-brown-bg" xs={2}>
            <Row className="d-flex" style={{ height: "90vh" }}>
              <Col xs={12} className="pt-5 align-self-start">
                <h3>Services</h3>
              </Col>
              <Col xs={12} className="align-self-end b-5">
                <h4>@brownstoneco</h4>
              </Col>
            </Row>
          </Col>
          <Col className="designer-bg" xs={5}></Col>
          <Col className="px-5 align-self-center" xs={5}>
            <h1 className="display-1">The Works</h1>
            <ShortHr size="medium" />
            <p>
              Let's talk through your design needs. During your consultation,
              the dream is defined. My signature Discovery Process is an
              enjoyable opportunity to discover a deeper understanding of your
              lifestyle goals, tastes, and design needs. We'll establish the
              scope of work, review the budget, and timeline. After the
              consultation, we provide the project scope, recommendations, and
              an action plan so you’ll have a clear understanding and outline of
              what is required to complete the project.
            </p>
            <p>
              The Discovery Call is our first opportunity to discuss your
              project and learn more about each other. Schedule a 30-minute
              phone conversation with a team member to review details. Every
              design collaboration begins with a Design Consultation, whether a
              one-time meeting or the first step in our design process of a
              full-service project.
            </p>
            <p>
              Full-service means your interior design project from concept to
              completion. I specialize in creating one-of-a-kind residential and
              commercial spaces, which range in scope from high-rise
              condominiums and urban estates to country farmhouses, coastal
              cottages, and more.
            </p>
            <p>
              <ul>
                <li>
                  Architectural details and Coordination with architects and
                  contractors
                </li>
                <li>Space planning and interior layout</li>
                <li>
                  Selection of furniture and accessories with placement and
                  styling
                </li>
                <li>Custom furniture and textile design</li>
                <li>
                  Color palette and painting consultation Delivery and
                  installation
                </li>
                <li>Budget planning</li>
              </ul>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className="full-screen light-brown-bg">
              <Col className="p-5">
                <div className="d-flex justify-content-center align-items-center square">
                  <h2 className="px-2">Interior</h2>
                </div>
              </Col>
              <Col className="p-5">
                <div className="d-flex justify-content-center align-items-center square">
                  <h2 className="px-2">Staging</h2>
                </div>
              </Col>
              <Col className="p-5">
                <div className="d-flex justify-content-center align-items-center square">
                  <h2 className="px-2">Decor</h2>
                </div>
              </Col>
              <Col className="p-5">
                <div className="d-flex justify-content-center align-items-center square">
                  <h2 className="px-2">Consulting</h2>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default Services;
