import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

function HouseListing() {
  const [houseListings, setHouseListings] = useState<any[]>();

  useEffect(() => {
    const houses = [
      {
        title: "123 South St Baltimore, MD",
        description: "3 Beds, 2 baths Colonial!",
        price: "$360,000",
        image: require("../../imgs/home1.jpeg"),
        id: 1,
      },
      {
        title: "123 South St Baltimore, MD",
        description: "3 Beds, 2 baths Colonial!",
        price: "$320,000",
        image: require("../../imgs/home2.jpeg"),
        id: 1,
      },
      {
        title: "123 South St Baltimore, MD",
        description: "3 Beds, 2 baths Colonial!",
        price: "$390,000",
        image: require("../../imgs/home1.jpeg"),
        id: 1,
      },
    ];

    setHouseListings(houses);
  }, []);

  return (
    <>
      <Container>
        <Row className="pt-4">
          {houseListings &&
            houseListings.map((house) => (
              <Col>
                <Card style={{ width: "18rem" }}>
                  <Card.Img variant="top" src={house.image} />
                  <Card.Body>
                    <Card.Title>{house.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      {house.price}
                    </Card.Subtitle>
                    <Card.Text>{house.description}</Card.Text>
                    <Button variant="primary">Virtural tour</Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </>
  );
}

export default HouseListing;
