import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../Comonponents/Footer/Footer";
import LineText from "../../Comonponents/LineText/LineText";
import ShortHr from "../../Comonponents/ShortHr/ShortHr";
import "./About.css";

function About() {
  return (
    <>
      <Container className="About light-brown-bg" fluid>
        <div className="p-4">
          <LineText rightText={false} text={"Story Time"} />
          <Row className="padded-row"></Row>
          <Row>
            <Col xs={6}>
              <h3 className="display-1">Ericka A. Corbett</h3>
              <p>
                I am a Maryland, DC, Virginia real estate agent who works with
                home buyers/sellers. After graduating from Southern University,
                A&M College, I had a hard time breaking into the Public
                Relations world and decided to take a chance and follow my
                passion and enthusiasm for helping others by creating and
                providing a sanctuary.
              </p>

              <p>
                This is my Passion! My Passion for design, architecture, and
                People along with my ability to curate and secure Dream spaces
                is my priority and duty to my clients. My enthusiasm for helping
                others, aesthetic eye, and hardworking determination helps me
                connect with clients and provide them with the best service and
                results possible.
              </p>
            </Col>
            <Col xs={6}>
              <img
                src={require("../../imgs/headshot.jpeg")}
                className="img-fluid"
                alt="..."
              />
            </Col>
          </Row>
          <Row className="padded-row"></Row>
          <LineText rightText={true} text={"Services"} />
          <Row className="padded-row"></Row>
          <Row>
            <Col xs={6}>
              <img
                src={require("../../imgs/about1.jpeg")}
                className="img-fluid"
                alt="..."
              />
            </Col>
            <Col xs={6}>
              <h3 className="display-1">What I do</h3>
              <p>
                As a realtor, my background in customer care and project
                management I provide directness, transparency, compelling
                listing marketing. My ability to curate personal listings,
                negotiate, and Close the Deal allows me to only Provide the very
                Best of everything for my clients.As a realtor, my only priority
                is to keep my clients happy and making sure they get everything
                on their wish list. As a conscientious realtor who exudes
                credibility, commitment and determination I will make sure you
                receive everything you are looking for throughout your reality
                process.
              </p>
            </Col>
            <Row className="pt-5">
              <Col
                className="egg-shell-bg p-5 shadowed-boarder-gray"
                xs={{ span: 6, offset: 5 }}
              >
                <h3>Realty Services</h3>
                <h6 className="text-secondary">
                  Buying, Selling, wholesaling, property management and renting
                </h6>
                <ShortHr size="small" />
              </Col>
            </Row>
            <Row className="p-1">
              <Col
                className="egg-shell-bg p-5 shadowed-boarder-black"
                xs={{ span: 6, offset: 1 }}
              >
                <h3>Interior Design</h3>
                <h6 className="text-secondary">
                  interior design, décor styling, concept consulting
                </h6>
                <ShortHr size="small" />
              </Col>
            </Row>
            <Row className="p-1">
              <Col
                className="egg-shell-bg p-5 shadowed-boarder-gray"
                xs={{ span: 6, offset: 5 }}
              >
                <h3>Property Staging</h3>
                <h6 className="text-secondary">
                  Short-term rental setup, office décor styling
                </h6>
                <ShortHr size="small" />
              </Col>
            </Row>
            <Row className="p-1">
              <Col
                className="egg-shell-bg p-5 shadowed-boarder-black"
                xs={{ span: 6, offset: 1 }}
              >
                <h3>Consulting</h3>
                <h6 className="text-secondary">
                  offering skills and creative talents to a real estate property
                  team
                </h6>
                <ShortHr size="small" />
              </Col>
            </Row>
          </Row>
          <Row className="padded-row"></Row>
          <LineText rightText={false} text={"Why me?"} />
          <Row className="padded-row"></Row>
          <Row>
            <Col className="" xs={6}>
              <img
                src={require("../../imgs/about1.jpeg")}
                className="img-fluid"
                alt="..."
              />
            </Col>
            <Col xs={6}>
              <h3 className="display-1">What you get</h3>
              <p>
                I Love what I do and I want it to be perfect! I get a great deal
                of satisfaction from Planning, Organizing, and Creating.
                Building an individualized relationship and Plan if action for
                each client, having an Organized and informed process for smooth
                transitions, and curating every available option to Create your
                vision.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  );
}
export default About;
